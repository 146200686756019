import React from "react"
import BoxOne from "./D2CNewPage/BoxOne"

import BoxTwo from "./D2CNewPage/BoxTwo"
import BoxFour from "./D2CNewPage/BoxFour"
import BoxThree from "./D2CNewPage/BoxThree"
import styled from "styled-components"
function d2c() {
  return (
    <div>
      <BoxOne />
      <BoxTwo />
      <Spacer />
      <BoxThree />
      <BoxFour />
    </div>
  )
}
export default d2c
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 30px;
  @media screen and (max-width: 600px) {
    height: 30px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 30px;
  }
`
