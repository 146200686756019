import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customerjourney from "../modules/SubFetaures/Customerjourney"
import D2C from "../modules/Solutions/D2C"

const d2c = () => (
  <Layout>
    <SEO
      title="D2C"
      description="Consumer decision making process is a complex process that involves identifying needs to finally concluding their buying decision.
    As you might want to employ different business approach for different regions/ industry sectors, 
    with Korero you have the flexibility to setup different processes for different regions/ industry sectors."
    />
    <D2C />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default d2c
